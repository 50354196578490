<template>
  <div class="content">
    <div class="tabs">
      <el-menu
        :default-active="$route.query.index ? $route.query.index : '1'"
        class="menu"
      >
        <el-menu-item index="1">
          <i class="icon el-icon-menu"></i>
          <span slot="title">企业信息</span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="icon el-icon-s-operation"></i>
          <span slot="title">展位类型</span>
        </el-menu-item>
        <el-menu-item index="3">
          <i class="icon el-icon-tickets"></i>
          <span slot="title">展品信息</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="icon el-icon-set-up"></i>
          <span slot="title">设施申报</span>
        </el-menu-item>
        <el-menu-item index="5">
          <i class="icon el-icon-receiving"></i>
          <span slot="title">租赁申报</span>
        </el-menu-item>
        <el-menu-item index="6">
          <i class="icon el-icon-collection"></i>
          <span slot="title">企业开票账户信息</span>
        </el-menu-item>
        <el-menu-item index="7">
          <i class="icon el-icon-document-checked"></i>
          <span slot="title">完成申报</span>
        </el-menu-item>
      </el-menu>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.content {
  background-color: #edf0f4;
  min-height: 100vh;
  position: relative;
  .tabs {
    position: absolute;
    top: 70px;
    left: 70px;
    width: 260px;
    .menu {
      .el-menu-item {
        font-size: 22px;
        margin: 26px 0;
        .icon {
          margin-right: 10px;
          font-size: 22px;
        }
      }
    }
  }
}
</style>
