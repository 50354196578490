<template>
  <div class="content">
    <div class="boxcontent">
      <div class="boxleft">
        <!-- <div
          class="item"
          :class="{ itemactive: itemactive === 0 }"
          @click="infoclick"
        >
          <i class="el-icon-user"></i>
          <span class="text">基本信息</span>
        </div> -->
        <div
          class="item"
          :class="{ itemactive: itemactive === 1 }"
          @click="myorderform"
        >
          <i class="el-icon-document"></i>
          <span class="text">我的订单</span>
        </div>
        <div
          class="item"
          :class="{ itemactive: itemactive === 2 }"
          @click="changepassword"
        >
          <i class="el-icon-edit-outline"></i>
          <span class="text">修改密码</span>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemactive: 1,
    };
  },
  computed: {},
  methods: {
    infoclick() {
      this.itemactive = 0;
      this.$router.push("/index/myinfo");
    },
    myorderform() {
      this.itemactive = 1;
      this.$router.push("/index/myinfo");
    },
    changepassword() {
      this.$router.push("/index/myinfo/changepassword");
      this.itemactive = 2;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  background-color: #f2f2f2;
  width: 100vw;
  min-height: auto !important;
  .boxcontent {
    width: 1300px;
    height: 750px;
    margin: 12px auto;
    display: flex;
    .boxleft {
      width: 200px;
      height: 300px;
      background-color: #fff;
      border: 1px solid #ccc;
      .item {
        height: 62px;
        display: flex;
        align-items: center;
        padding-left: 18px;
        &:hover {
          cursor: pointer;
        }
        .text {
          margin-left: 24px;
        }
      }
      .itemactive {
        background-color: #f2f2f2;
      }
    }
  }
}
</style>
