import http from '../http'
// 获取用户信息
export const getUserInfo = () => {
        return http({
            url: '/web/user/getInfo',
            method: 'get'
        })
    }
    //编辑用户信息
export const editUser = (data) => {
    return http({
        url: '/web/user/updateUser',
        method: 'post',
        data
    })
}

//忘记密码
export const forgetPass = (params) => {
    return http({
        url: '/web/user/updatePass',
        method: 'get',
        params
    })
}

//查询轮播

export const getBanner = () => {
    return http({
        url: '/web/banner/tbBanner/page',
        method: 'get'
    })
}

//文件下载列表

export const getFile = (params) => {
    return http({
        url: '/web/file/tbFile/page',
        method: 'get',
        params
    })
}